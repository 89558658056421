<template lang="pug">
	section#novaSenha
		.wrapper
			div.novaSenha
				h2 Cadastre sua nova senha.
				// p Lorem ipsum dolor sit amet, consectetur adip incididunt ut labore et dolore magna aliqua.
				form(v-on:submit.prevent="sendReset", autocomplete="off")
					.input(:class="{error: errors.password }")
						input(type="password", placeholder="Senha" v-model="registrar.password")
						span(v-if="errors.password") {{errors.password[0]}}
					.input(:class="{error: errors.re_password }")
						input(type="password", placeholder="Confirmação de senha" v-model="registrar.re_password")
						span(v-if="errors.re_password") {{errors.re_password[0]}}
					p
						small A senha deve conter: de 6 a 12 caracteres, sendo ao menos uma maiúscula, uma minúscula, um número e um caracter especial(@!*$#%.)
					button Enviar
					span(v-if="errors.token").erro {{errors.token[0]}}
				span(v-if="message") {{ message }}
</template>

<script>
export default {
	data() {
		return {
			registrar: {
				token: ""
			},
			errors: {},
			message: null
		};
	},
	mounted() {
		this.registrar.token = this.$route.query.token;
	},
	methods: {
		sendReset() {
			this.message = null;
			this.$axios
				.post(`api/password/reset`, this.registrar)
				// eslint-disable-next-line
				.then(response => {
					this.message = "Senha alterada com sucesso."
					this.registrar = {}

					setTimeout(() => {
						this.$router.replace({
							name: 'login'
						})
					}, 2000)
				})
				.catch(error => {
					if (error.response.status == 422)
						this.errors = error.response.data.errors;
				});
		}
	}
};
</script>

<style lang="stylus" scoped src="./NovaSenha.styl"></style>
